.red-group-container {
  width: 100%;
  border-radius: 5px;
  border-width: 1px;
  border: 1px solid #f47174;
  /* border: 2px solid #ccc; */
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  margin-top: 10px;
  margin-bottom: 20px;
}
